const title = '全流程追溯和分析软件系统'

// 同时展示两级菜单
const useSecMenu = true

const storageKey = {
  userInfo: 'userInfo',
  permission: 'permission',
  menu: 'menu',
  token: 'token'
}

// 配置菜单的属性
const menuDefault = {
  iconDefault: 'iconfont icon-caidan',
  props: {
    label: 'name',
    path: 'path',
    icon: 'icon',
    children: 'children'
  }
}
const flowDesignUrl = 'http://localhost:7081'

const config = {
  development: {
    // baseUrl: 'http://192.168.2.27:6626/admin'
    // baseUrl: ' http://natapp.zcondigit.com/admin'
    baseUrl: 'http://localhost:6626/admin'
  },
  production: {
    baseUrl: 'https://smpo.avatarget.com.cn/admin'
  }
}
const env = process.env

const baseUrl = config[env.NODE_ENV].baseUrl
const fileSaveUrl = config[env.NODE_ENV].baseUrl + '/viewImg/'
const flowUrl = baseUrl + '/flow/process/diagram-view?processInstanceId='

export {
  flowDesignUrl,
  flowUrl,
  storageKey,
  title,
  useSecMenu,
  baseUrl,
  fileSaveUrl,
  menuDefault
}
