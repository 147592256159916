<template>
  <div class="vue-logo">
    <template>
      <span class="vue-logo_title" key="1">
        <div v-if="!isCollapse"> <img  class="vue-logo_title-logo" src="~@/assets/img/logo.png"/>

          <div class="logo-title">{{ title }}</div>
        </div>
        <div v-else>G</div>
      </span>
    </template>
  </div>
</template>

<script>
import { title } from '@/config'
import { mapGetters } from 'vuex'

export default {
  name: 'Logo',
  data () {
    return {
      title: title
    }
  },
  created () {
  },
  computed: { ...mapGetters(['isCollapse']) },
  methods: {}
}
</script>

<style lang="scss">
.logo-title{
  line-height: 24px;
  width: 100px;
}
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter-active {
  transition: opacity 2.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.vue-logo_title-logo{
  width: 46px;
  height: 46px;
  margin-right: 20px;
}
.vue-logo {
  position: fixed;
  top: 0;
  left: 0;
  width: 200px;
  height: 64px;
  line-height: 64px;
  background-color: #20222a;
  font-size: 20px;
  overflow: hidden;
  box-sizing: border-box;
  color: rgba(255, 255, 255, 0.8);
  z-index: 1024;

  &_title {
    display: block;
    text-align: center;
    font-weight: 300;
    font-size: 16px;
    font-weight: bold;
    &>div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 10px;
    }
  }

  &_subtitle {
    padding-top: 10px;
    display: block;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
  }
}
</style>
