const RouterPlugin = function () {
  this.$router = null
  this.$store = null
}
RouterPlugin.install = function (vue, router, store) {
  this.$router = router
  this.$store = store

  function isURL (s) {
    return /^http[s]?:\/\/.*/.test(s)
  }

  this.$router.$vueRouter = {
    // 全局配置
    $menuDefault: this.$store.getters.menuDefault,
    routerList: [],
    group: '',
    meta: {},
    safe: this,
    // 设置标题
    setTitle: (title) => {
      const defaultTitle = '全流程追溯和分析软件系统'
      title = title ? `${title}-${defaultTitle}` : defaultTitle
      document.title = title
    },
    // 处理路由
    getPath: function (params) {
      const { src } = params
      return src || '/'
    },
    // 正则处理路由
    validPath: function (list, path) {
      let result = false
      list.forEach(ele => {
        if (new RegExp('^' + ele + '.*', 'g').test(path)) {
          result = true
        }
      })
      return result
    },
    // 设置路由值
    getValue: function (route) {
      let value = ''
      if (route.query.src) {
        value = route.query.src
      } else {
        value = route.path
      }
      return value
    },
    // 动态路由
    formatRoutes: function (aMenu = [], first) {
      const aRouter = []
      const propsConfig = this.$menuDefault.props
      const propsDefault = {
        label: propsConfig.label || 'name',
        path: propsConfig.path || 'path',
        icon: propsConfig.icon || 'icon',
        children: propsConfig.children || 'children',
        meta: propsConfig.meta || 'meta'
      }
      if (aMenu.length === 0) return
      for (let i = 0; i < aMenu.length; i++) {
        const oMenu = aMenu[i]
        // 特殊处理组件
        let filePath = oMenu.path
        if (oMenu.path.indexOf('?') !== -1) {
          filePath = oMenu.path.substring(0, oMenu.path.indexOf('?'))
        }
        if (this.routerList.includes(filePath)) return
        const component = 'views' + filePath
        const name = oMenu[propsDefault.label]
        const icon = oMenu[propsDefault.icon]
        const children = oMenu[propsDefault.children]
        const meta = oMenu[propsDefault.meta] || {}
        const isChild = children.length !== 0
        // console.log(component)
        const oRouter = {
          path: filePath,
          component (resolve) {
            // 判断是否为首路由
            if (first) {
              require(['../page/index'], resolve)
              // require([`../${component}.vue`], resolve)
              // 判断是否为多层路由
            } else if (isChild) {
              require(['../page/index/layout'], resolve)
              // 判断是否为最终的页面视图
            } else {
              if (component === 'views/exp/primaryculture/step') {
                console.log(1)
              }
              require([`../${component}.vue`], resolve)
            }
          },
          test: component,
          name: name,
          icon: icon,
          meta: meta,
          redirect: (() => {
            if (!isChild && first && !isURL(filePath)) return ''
            else return ''
          })(),
          // 处理是否为一级路由
          // eslint-disable-next-line multiline-ternary
          children: !isChild ? (() => {
            // if (first) {
            //   if (!isURL(path)) filePath = `${path}/index`
            return [{
              component (resolve) {
                require([`../${component}.vue`], resolve)
              },
              icon: icon,
              name: name,
              meta: meta,
              path: filePath
            }]
            // }
            // return []
          })() : (() => {
            return this.formatRoutes(children, false)
          })()
        }
        aRouter.push(oRouter)
      }
      // console.log(aRouter)
      if (first) {
        if (!this.routerList.includes(aRouter[0][propsDefault.path])) {
          this.safe.$router.addRoutes(aRouter)
          this.routerList.push(aRouter[0][propsDefault.path])
        }
      } else {
        return aRouter
      }
    }
  }
}
export default RouterPlugin
