<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  data () {
    return {}
  },
  watch: {},
  created () {
  },
  methods: {},
  computed: {}
}
</script>
<style lang="scss">

#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
